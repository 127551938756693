import React from 'react';
import { Link } from 'react-router-dom';
import staffPhoto from '../images/staff-photo.jpg';
import styles from '../styles/Home.module.css';
import { useNavbar } from '../context/NavbarContext';

export default function Home() {
  const { closeNav } = useNavbar();

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={staffPhoto} alt="Staff" className={styles.image} />
      </div>
      <div className={styles['text-overlay']}>
        <h1 className={styles['my-element']}>Bridging the gap between talent and opportunity</h1>
        <div className={styles.buttonsContainer}>
          <Link to="/find-job" className={styles.button} onClick={closeNav}>FIND A JOB</Link>
          <Link to="/find-talent" className={styles.button} onClick={closeNav}>FIND TALENT</Link>
        </div>
      </div>
    </div>
  );
}
